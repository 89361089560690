import axios, {AxiosError, AxiosResponse} from "axios"
import {toast} from "@zerodevx/svelte-toast"
import {AccessDeniedResponse, isAccessDeniedEvent} from "@utils/Permissions"
import {router} from "@inertiajs/svelte"

export interface ResponseMessage
{
    type: string
    msg: string
}


const axiosClient = axios.create()

axiosClient.interceptors.request.use(config => {
    if(!config.headers) {
        // @ts-ignore
        config.headers = {}
    }

    config.headers["X-Requested-With"] = "XMLHttpRequest"

    return config
})

axiosClient.interceptors.response.use((response: AxiosResponse<any & { __message: ResponseMessage } | AccessDeniedResponse>) => {
    if(response.data?.__inertia_visit !== undefined) {
        router.visit(response.data.__inertia_visit)
    }

    if(response.data?.__redirect !== undefined) {
        window.location.assign(response.data.__redirect)
    }

    const message = response.data?.__message ?? response.data?.props?.__message
    if (message !== undefined) {
        if (isAccessDeniedEvent({
            detail: { response }
        } as CustomEvent)) {
            if (response.data.__redirectToLogin !== undefined) {
                window.location.assign(response.data.__redirectToLogin)
            } else if (!response.data.silent) {
                toast.push({
                    msg: response.data.message ?? "Chyba na serveru",
                    duration: 4000 + ((response.data.message?.length ?? 0) * 15),
                    dismissable: true,
                    classes: ["msd-toast-access-denied"],
                    pausable: true,
                })
            }

            return response
        }

        toast.push({
            msg: message.msg,
            duration: 4000 + (message.msg.length * 15),
            dismissable: true,
            classes: [message.type],
            pausable: true,
        })
    }

    return response
}, (error: AxiosError) => {
    const response = error?.response as AxiosResponse|undefined
    const data: AccessDeniedResponse|undefined = response?.data
    if(data !== undefined) {
        if(data.__inertia_visit !== undefined) {
            router.visit(data.__inertia_visit)
        }

        if (data.__redirectToLogin !== undefined) {
            window.location.assign(data.__redirectToLogin)
        } else if (!data.silent) {
            toast.push({
                msg: data.message ?? "Chyba na serveru",
                duration: 4000 + ((data.message?.length ?? 0) * 15),
                dismissable: true,
                classes: ["msd-toast-access-denied"],
                pausable: true,
            })
        }
    }

    return Promise.reject(error)
})

export default axiosClient
